import { FC, useCallback, useEffect, useState } from "react";

import BackButton from "@/components/backButton/BackButton";
import Image from "@/components/image/Image";

import styles from "./MeinvChapter.module.css";
import useRequest from "@/hooks/useRequest";
import { useNavigate, useSearchParams } from "react-router-dom";
import Post from "@/components/post/Post";
import Pagination from "@/components/pagination/Pagination";
import u from "@/util";
import { useHeader } from "@/contexts/header.context";
import Wrapper from "@/components/wrapper/Wrapper";

interface IMeinvChapterDetail {
  name: string;
  thumb: string;
  chapters: {
    data: [];
    last_page: number;
    total: number;
  };
  update_time: number;
}

const MeinvChapter: FC = () => {
  const navigate = useNavigate();
  const { setIsMobileHeaderChildOpen } = useHeader();
  const [searchParams, setSearchParams] = useSearchParams();

  const [active, setActive] = useState(1);
  const [meinvChapterDetail, setMeinvChapterDetail] =
    useState<IMeinvChapterDetail>();

  const { makeRquest: requestMeinvChapter } = useRequest(
    "pic/chapter-meinv",
    "post"
  );

  const fetchMeinvChapter = useCallback(
    async (pageNum = 1) => {
      try {
        const {
          data: { chapter },
        } = await requestMeinvChapter({
          id: searchParams.get("id"),
          page: pageNum || searchParams.get("page"),
        });
        console.log("chapter", chapter);
        setMeinvChapterDetail(chapter || {});
      } catch (err) {
        console.log(err);
      }
    },
    [requestMeinvChapter, searchParams]
  );

  const handlePostOnClick = (postValue: any) => {
    const id = (postValue && postValue.id) || 1;
    navigate(
      `/page/category/jpmv/detail?id=${id}&meinv=${postValue.cat_id || 1}`
    );
  };

  const activeHandler = (clickedActive: any) => {
    const num = parseInt(clickedActive);
    setActive(num);
    fetchMeinvChapter(num);
    setSearchParams({
      id: searchParams.get("id") || "",
      page: num.toString(),
    });
  };

  useEffect(() => {
    const page = parseInt(searchParams.get("page") || "1");
    fetchMeinvChapter(page);
    setActive(page);
  }, [searchParams]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [searchParams]);

  useEffect(() => {
    setIsMobileHeaderChildOpen(false);
  }, []);

  return (
    <Wrapper>
      <div className={styles.meinvChapterBodyContainer}>
        <BackButton />
        <div className={styles.meinvChapterContent}>
          <div className={styles.meinvChapterActorContainer}>
            <div className={styles.meinvChapterActor}>
              <div className={styles.actorProfile}>
                <Image
                  srcValue={
                    (meinvChapterDetail && meinvChapterDetail.thumb) || ""
                  }
                  className={styles.iCvr}
                />
                <Image
                  srcValue={
                    (meinvChapterDetail && meinvChapterDetail.thumb) || ""
                  }
                  className={styles.actorProfileImage}
                />
              </div>
              <div className={styles.meinvChapterActorDesc}>
                <p className={styles.title}>
                  {(meinvChapterDetail && meinvChapterDetail.name) || "-"}
                </p>
                <div className={styles.desc}>
                  <p className={styles.category}>类别：撸撸图区</p>
                  <p className={styles.time}>
                    更新时间：
                    {u.toFmt(
                      meinvChapterDetail &&
                        meinvChapterDetail.update_time * 1000
                    ) || "-"}
                    {/* {(meinvChapterDetail && u.toFmt(meinvChapterDetail.insert_time * 1000)) ||
                      "-"} */}
                  </p>
                </div>
                <div className={styles.labelViews}>
                  {/* <div className={styles.label}>
      <img src={pic} alt="pic" />
      <p>12P</p>
    </div> */}
                  <div className={styles.views}>
                    {/* <img src={eye} alt="eye" /> */}
                    <p>
                      共
                      {meinvChapterDetail &&
                        meinvChapterDetail.chapters &&
                        meinvChapterDetail.chapters.total}
                      期
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.meinvChapterPosts}>
              {meinvChapterDetail &&
                meinvChapterDetail.chapters &&
                meinvChapterDetail.chapters.data.map((val: any) => (
                  <Post
                    post={val}
                    key={val.id}
                    onClick={handlePostOnClick}
                  ></Post>
                ))}
            </div>
          </div>
        </div>
        <Pagination
          active={active}
          size={meinvChapterDetail?.chapters.last_page || 1}
          total={meinvChapterDetail?.chapters.total || 0}
          step={u.isMobile() ? 1 : 3}
          onClickHandler={activeHandler}
        />
      </div>
    </Wrapper>
  );
};

export default MeinvChapter;
