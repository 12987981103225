import { FC, useState } from "react";
import moment from "moment";
import styles from "./Footer.module.css";

import logo from "../../assets/logo-white.png";
import arrow from "../../assets/vector.png";
import u from "@/util";

const menuList = [
  {
    title: "信息",
    children: [
      {
        name: "条款和条件",
        url: "/information/terms",
      },
      {
        name: "隐私政策",
        url: "/information/privacy",
      },
      {
        name: "DMCA",
        url: "/information/dmca",
      },
      {
        name: "2257",
        url: "/information/2257",
      },
      {
        name: "EU DSA",
        url: "/information/dsa",
      },
    ],
  },
  {
    title: "加入我们",
    children: [
      {
        name: "内容合作商",
        event: u.tgOpen
        // url: "https://t.me/dalilimm",
      },
    ],
  },
  {
    title: "支持与帮助",
    children: [
      {
        name: "内容移除",
        event: u.kefuOpen
        // url: "https://mm.99b1b438eb1b4006.pw/chat/pc?noCanClose=1&token=7111d49129d7ebf85aa96e1b635c76ad",
      },
      {
        name: "寻取帮助",
        event: u.kefuOpen
        // url: "https://mm.99b1b438eb1b4006.pw/chat/pc?noCanClose=1&token=7111d49129d7ebf85aa96e1b635c76ad",
      },
      {
        name: "信任与安全",
        url: "/information/trust-safety",
      },
      {
        name: "家长控制",
        url: "/information/parental-control",
      },
      {
        name: "反馈",
        event: u.kefuOpen
        // url: "https://mm.99b1b438eb1b4006.pw/chat/pc?noCanClose=1&token=7111d49129d7ebf85aa96e1b635c76ad",
      },
    ],
  },
  {
    title: "连接",
    children: [
      {
        name: "海外永久域名",
        url: "https://www.maomiav.com/",
      },
    ],
  },
];

const Footer: FC = () => {
  const [open, setOpen] = useState(0);

  return (
    <div className={styles.footer}>
      <div className={styles.footerHeader}>
        <div className={styles.footerDesc}>
          <p>
            警告：如果您未滿18歲或您當地法律許可之法定年齡、或是對情色反感或是衛道人士建議您離開本站！
            本站歸類為限制級、限定為成年者已具有完整行為能力且願接受本站內影音內容、及各項條款之網友才可瀏覽，未滿18歲謝絕進入。
          </p>
          <p>
            本站已遵照「iWIN網路內容防護機構」進行分類，如有家長發現未成年兒童／少年瀏覽此站、請按照此方法過濾本站內容『網站分級制度』
          </p>
        </div>
        <div className={styles.footerMenuList}>
          {menuList.map((item, index) => {
            return (
              <div key={index} className={styles.menuListCard}>
                <div className={styles.menuTitle}>{item.title}</div>
                <div className={styles.menuList}>
                  <ul>
                   {item.children.map((x, i) => {
                      if(x.event){
                        return (
                          <li key={i}>
                            <p onClick={x.event}>{x.name}</p>
                          </li>
                        );
                      }

                      return (
                        <li key={i}>
                          <a href={x.url} target="_blank" rel="noreferrer">
                            {x.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.footerMenuMobileList}>
          {menuList.map((item, index) => {
            return (
              <div key={index} onClick={() => setOpen(index + 1)}>
                <div className={styles.footerMenuTitleContainer}>
                  <div className={styles.menuTitle}>{item.title}</div>
                  <img width={12} height={6} src={arrow} alt="menuHome" />
                </div>
                <div className={styles.menuList}>
                <ul>
                  {open === index + 1 &&
                    item.children.map((x, i) => {
                      if(x.event){
                        return (
                          <li key={i}>
                            <p onClick={x.event}>{x.name}</p>
                          </li>
                        );
                      }

                      return (
                        <li key={i}>
                          <a href={x.url} target="_blank" rel="noreferrer">
                            {x.name}
                          </a>
                        </li>
                      );
                    })}
                </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.footerCopyright}>
        <a href="https://www.maomiav.com/">
          &copy; maomiav.com, {moment().year()}
        </a>
        <img src={logo} alt="logo" width={82} height={18} />
      </div>
    </div>
  );
};

export default Footer;
