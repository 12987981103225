import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import useRequest from "@/hooks/useRequest";
import { MEINV_TUPIAN } from "@/const/prod.const";

import BackButton from "@/components/backButton/BackButton";
import Post from "@/components/post/Post";
import Pagination from "@/components/pagination/Pagination";

import notFound from "../../assets/no-found.png";

import styles from "./Category.module.css";
import u from "@/util";
import HotArea from "@/components/hotArea/HotArea";
import Wrapper from "@/components/wrapper/Wrapper";

interface ICiliList {
  data: [];
  last_page: number;
  total: number;
}

const Category: FC = () => {
  const navigate = useNavigate();
  const { jump_name = "" } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { makeRquest: requestList } = useRequest("pic/list-tupian", "post");
  const { makeRquest: requestMeiNvList } = useRequest("pic/list-meinv", "post");

  const [active, setActive] = useState(1);
  const [loading, setLoading] = useState(false);
  const [ciliList, setCiliList] = useState<ICiliList>({
    data: [],
    last_page: 0,
    total: 0,
  });
  const [meinvCiliList, setMeinvCiliList] = useState([]);

  const fetchList = useCallback(
    async (pageNum = 1) => {
      setLoading(true);
      try {
        const {
          data: { list },
        } = await requestList({
          order: 1,
          page: pageNum || searchParams.get("page"),
          name: jump_name,
        });
        // console.log("list-fetchList", list);
        setCiliList(list || {});
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    },

    [jump_name, requestList, searchParams]
  );

  const fetchMeiNvList = useCallback(
    async (page = 1) => {
      setLoading(true);
      try {
        const {
          data: { list },
        } = await requestMeiNvList({
          order: 1,
          page: page || 1,
          name: jump_name,
        });
        setMeinvCiliList(list || []);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    },
    [jump_name, requestList, searchParams]
  );

  const handlePostOnClick = (postValue: any) => {
    const id = (postValue && postValue.id) || 1;
    navigate(`/page/category/${jump_name}/detail?id=${id}`);
  };

  const handleMeinvPostOnClick = (postValue: any) => {
    const id = (postValue && postValue.id) || 1;
    navigate(`/page/meinv/chapter?id=${id}&page=1`);
  };

  const activeHandler = (clickedActive: any) => {
    const num = parseInt(clickedActive);
    setActive(num);
    fetchList(num);
    setSearchParams({
      name: searchParams.get("name") || "",
      page: num.toString(),
    });
  };

  useEffect(() => {
    if (MEINV_TUPIAN.includes(jump_name)) {
      fetchMeiNvList();
    } else {
      fetchList();
    }
  }, [jump_name]);

  useEffect(() => {
    const page = parseInt(searchParams.get("page") || "1");
    fetchList(page);
    setActive(page);
  }, [searchParams]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [searchParams]);

  return (
    <Wrapper>
      {MEINV_TUPIAN.includes(jump_name) ? (
        <div className={styles.categoryBodyContainer}>
          <HotArea />
          <div className={styles.categoryBackSection}>
            <BackButton />
            <div className={styles.categoryPostsTitle}>
              <p>{searchParams.get("name") || "-"}</p>
            </div>
          </div>
          {loading && meinvCiliList.length !== 0 ? (
            <div className={styles.categoryPostsLoading}>
              <img src={notFound} alt="not-found" />
            </div>
          ) : (
            <div className={styles.categoryPosts}>
              <div className={styles.meinvCategoryPostsContainer}>
                {meinvCiliList &&
                  meinvCiliList?.map((val: any) => (
                    <Post
                      post={val}
                      key={val.id}
                      onClick={handleMeinvPostOnClick}
                    ></Post>
                  ))}
              </div>
            </div>
          )}
          {meinvCiliList && meinvCiliList.length === 0 && (
            <div className={styles.notFound}>
              <img src={notFound} alt="not-found" />
            </div>
          )}
        </div>
      ) : (
        <div className={styles.categoryBodyContainer}>
          <HotArea />
          <div className={styles.categoryBackSection}>
            <BackButton />
            <div className={styles.categoryPostsTitle}>
              <p>{searchParams.get("name") || "-"}</p>
            </div>
          </div>
          {loading && ciliList.data.length !== 0 ? (
            <div className={styles.categoryPostsLoading}>
              <img src={notFound} alt="not-found" />
            </div>
          ) : (
            <div className={styles.categoryPosts}>
              <div className={styles.categoryPostsContainer}>
                {ciliList &&
                  ciliList.data.map((val: any) => (
                    <Post
                      post={val}
                      key={val.id}
                      onClick={handlePostOnClick}
                    ></Post>
                  ))}
              </div>
            </div>
          )}
          {ciliList && ciliList.data.length === 0 && (
            <div className={styles.notFound}>
              <img src={notFound} alt="not-found" />
            </div>
          )}
          <Pagination
            active={active}
            size={ciliList?.last_page || 1}
            total={ciliList?.total || 0}
            step={u.isMobile() ? 1 : 3}
            onClickHandler={activeHandler}
          />
        </div>
      )}
    </Wrapper>
  );
};

export default Category;
