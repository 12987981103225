import { FC } from "react";
import { useNavigate } from "react-router-dom";

import back from "../../assets/small-left.png";

import styles from './BackButton.module.css';

const BackButton: FC = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.backButton} onClick={goBack}>
      <img src={back} alt="back" />
    </div>
  );
};
export default BackButton;
