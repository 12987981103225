import { FC } from "react";
import { useNavigate } from "react-router-dom";

import Post from "../post/Post";

// import right from "../../assets/arrow-right.svg";
import styles from "./PostList.module.css";
import { MEINV_TUPIAN } from "@/const/prod.const";
import useRequest from "@/hooks/useRequest";

interface IPostList {
  postListTitle: string;
  postListJumpName: string;
  postList: [];
}

const PostList: FC<IPostList> = ({
  postListTitle,
  postListJumpName,
  postList,
}) => {
  const navigate = useNavigate();
  const { statisticsRequest } = useRequest("statistics/more", "post");

  const handleStatisticsMenu = async (name: string) => {
    try {
      const params = {
        category: "tupian",
        name,
      };
      await statisticsRequest(params);
    } catch (err) {
      console.log(err);
    }
  };
  
  const handleNavigateMore = () => {
    handleStatisticsMenu(postListTitle);
    navigate(`/page/category/${postListJumpName}?name=${postListTitle}&page=1`);
  };

  const handlePostOnClick = (postValue: any) => {
    const channel = (postValue && postValue.channel) || "";
    const id = (postValue && postValue.id) || 1;
    if (channel === "meinv") {
      navigate(`/page/meinv/chapter?id=${id}&page=1`);
    } else {
      navigate(`/page/category/${postListJumpName}/detail?id=${id}`);
    }
  };

  return (
    <div className={styles.masterPosts}>
      <div className={styles.masterPostsTitle}>
        <p className={styles.postsTitle}>{postListTitle}</p>
        <p className={styles.more} onClick={handleNavigateMore}>
          更多
          <img
            src={`${process.env.REACT_APP_LOCAL_IMAGE}/images/arrow-right.svg`}
            alt="right"
          />
        </p>
      </div>
      {MEINV_TUPIAN.includes(postListJumpName) ? (
        <div className={styles.masterPostsMeinvContainer}>
          {postList.map((val: any) => (
            <Post post={val} key={val.id} onClick={handlePostOnClick}></Post>
          ))}
        </div>
      ) : (
        <div className={styles.masterPostsContainer}>
          {postList.map((val: any) => (
            <Post post={val} key={val.id} onClick={handlePostOnClick}></Post>
          ))}
        </div>
      )}
    </div>
  );
};
export default PostList;
