import { createBrowserRouter, Navigate } from "react-router-dom";
import React from "react";
// import SharedLayout from "./pages/SharedLayout";
import Home from "./pages/home/Home";
import Category from "./pages/category/Category";
import PicDetail from "./pages/picDetail/PicDetail";
import MeinvChapter from "./pages/meinvChapter/MeinvChapter";
import Enter from "./pages/enter/Enter";
import Help from "./pages/help/Help";

const Terms = React.lazy(()=> import("./pages/information/Terms"));
const Info2257 = React.lazy(()=> import("./pages/information/2257"));
const DMCA = React.lazy(()=> import("./pages/information/DMCA"));
const DSA = React.lazy(()=> import("./pages/information/DSA"));
const Privacy = React.lazy(()=> import("./pages/information/Privacy"));
const TrustSafety = React.lazy(()=> import("./pages/information/TrustSafety"));
const ParentalControl = React.lazy(()=> import("./pages/information/ParentalControl"));

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Enter />,
  },
  {
    path: "/main",
    element: <Home />,
  },
  {
    path: "page/category/:jump_name",
    element: <Category />,
  },
  {
    path: "page/category/:jump_name/detail",
    element: <PicDetail />,
  },
  {
    path: "page/meinv/chapter",
    element: <MeinvChapter />,
  },
  {
    path: "/information/terms",
    element: <Terms />,
  },
  {
    path: "/information/privacy",
    element: <Privacy />,
  },
  {
    path: "/information/dmca",
    element: <DMCA />,
  },
  {
    path: "/information/2257",
    element: <Info2257 />,
  },
  {
    path: "/information/dsa",
    element: <DSA />,
  },
  {
    path: "/information/trust-safety",
    element: <TrustSafety />,
  },
  {
    path: "/information/parental-control",
    element: <ParentalControl />,
  },
  {
    path: "/help",
    element: <Help/>,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);