import { FC, useEffect, useRef } from "react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

import { usePictureIndex } from "@/contexts/pic.context";
// import { useConfigBase } from "@/contexts/configBase.context";
import Image from "@/components/image/Image";
import u from "@/util";

// import left from "../../assets/arrow-left.svg";
// import right from "../../assets/arrow-right.svg";

import styles from "./Home.module.css";
import { useNavigate } from "react-router-dom";
import PostList from "@/components/postList/PostList";
import { useHeader } from "@/contexts/header.context";
import HotArea from "@/components/hotArea/HotArea";

import Wrapper from "@/components/wrapper/Wrapper";

const Home: FC = () => {
  // const navigate = useNavigate();
  // const { configBaseList } = useConfigBase();
  const { setIsMobileHeaderChildOpen } = useHeader();
  const { banners, lists } = usePictureIndex();

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  // const handleCategoryClick = (item: any) => {
  //   navigate(`/page/category/${item.jump_name}?name=${item.name}&page=1`);
  // };

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    setIsMobileHeaderChildOpen(false);
  }, []);

  return (
    <Wrapper>
      <div className={styles.homeBodyContainer}>
        <div className={styles.swiperContainer}>
          <Swiper
            className={styles.swipeNav}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            slidesPerView={u.isMobile() ? 1 : 2}
            spaceBetween={15}
            pagination={{
              clickable: true,
            }}
            mousewheel={{
              releaseOnEdges: true,
              forceToAxis: true,
            }}
            freeMode={true}
            // navigation={{
            //   prevEl: navigationPrevRef.current,
            //   nextEl: navigationNextRef.current,
            // }}
            // onBeforeInit={(swiper: any) => {
            //   swiper.params.navigation.prevEl = navigationPrevRef.current;
            //   swiper.params.navigation.nextEl = navigationNextRef.current;
            // }}
            modules={[Navigation, Pagination, Autoplay]}
          >
            {banners.map((item: any, index: number) => {
              const hostname = window.location.origin;
              let domainLink = "/";

              if (item.url === "main") {
                domainLink = hostname.replace("pic.", "");
              }

              if (item.url === "download") {
                domainLink = hostname.replace("pic.", "download.");
              }

              return (
                <SwiperSlide
                  key={index}
                  onClick={() => window.open(domainLink, "_blank")}
                >
                  <Image
                    key={index}
                    srcValue={item && item.img}
                    className={styles.swipeNavImg}
                  />
                </SwiperSlide>
              );
            })}
            {/* <div className={styles.arrowGroup}>
            <div ref={navigationPrevRef} className={styles.arrowLeft}>
              <img src={left} alt="left" />
            </div>
            <div ref={navigationNextRef} className={styles.arrowRight}>
              <img src={right} alt="right" />
            </div>
          </div> */}
          </Swiper>
        </div>
        <HotArea />
        <div className={styles.lists}>
          {lists.map((list, index) => (
            <div key={index} className={styles.listsItem}>
              <PostList
                postListTitle={list.title || ""}
                postListJumpName={list.jump_name || ""}
                postList={list.list || []}
              />
              <div className={styles.divider}></div>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};
export default Home;
