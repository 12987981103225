import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import FloatButton from "../../components/floatButton/FloatButton";

const Wrapper = ({ children }: any) => {
  return (
    <>
      <Header />
      <div className="bodyContainer">{children}</div>
      <Footer />
      <FloatButton />
    </>
  );
};

export default Wrapper;
