import React, { createContext, useState } from "react";
import { useContext } from "react";

interface ReactNodeProps {
  children: React.ReactNode;
}

export type HeaderContextType = {
  isMobileHeaderChildOpen: boolean
  setIsMobileHeaderChildOpen: any
};

export const HeaderContext = createContext<HeaderContextType>({
  isMobileHeaderChildOpen: false,
  setIsMobileHeaderChildOpen: () => {},
});

export const HeaderProvider: React.FC<ReactNodeProps> = ({ children }) => {
  const [isMobileHeaderChildOpen, setIsMobileHeaderChildOpen] = useState(false);

  const value = { isMobileHeaderChildOpen, setIsMobileHeaderChildOpen };
  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};

export const useHeader = () => {
  return useContext(HeaderContext);
};
