import { FC, useState } from "react";
import styles from "./Pagination.module.css";

interface IPagination {
  active: number;
  size: number;
  step: number;
  total: number;
  onClickHandler: (val: any) => void;
}

const Pagination: FC<IPagination> = ({
  active,
  size,
  step,
  total,
  onClickHandler,
}) => {
  const showingNumbers = step * 2 + 1;
  let startNumber = 2;
  let startArrayNumber = step;

  let needStartDots = false;
  let needEndDots = false;

  if (active > step) {
    startArrayNumber = active - step;

    needStartDots = active > step + startNumber ? true : false;
  }

  if (size > showingNumbers) {
    needEndDots = size > active + step + 1 ? true : false;

    if (size < active + step + 1) {
      startArrayNumber = size - showingNumbers;
    }
  }

  let contentNumber;

  return (
    <>
      {total > 0 && (
        <ul className={styles.pagination}>
          {active > 1 ? (
            <li
              className={`${styles.pageItem} ${styles.prev} ${styles.arrowIcon}`}
              onClick={() => onClickHandler(active - 1)}
            >
              &#x2039;
            </li>
          ) : (
            <li
              className={`${styles.pageItem} ${styles.prev} ${styles.arrowIcon} ${styles.disabled}`}
            >
              &#x2039;
            </li>
          )}
          {size > showingNumbers + startNumber ? (
            <>
              <li
                onClick={(e) => onClickHandler(e.currentTarget.textContent)}
                className={`${styles.pageItem} ${
                  active === 1 && `${styles.active}`
                }`}
              >
                1
              </li>

              {needStartDots && <span>...</span>}
              {[...Array(showingNumbers)].map((item, index) => {
                const number = (contentNumber = needStartDots
                  ? startArrayNumber
                  : startNumber);
                startNumber++;
                startArrayNumber++;
                return (
                  <li
                    key={index}
                    className={`${styles.pageItem} ${
                      active === contentNumber && `${styles.active}`
                    }`}
                    onClick={(e) => onClickHandler(e.currentTarget.textContent)}
                  >
                    {number}
                  </li>
                );
              })}
              {needEndDots && <span>...</span>}
              <li
                className={`${styles.pageItem} ${
                  active === size && `${styles.active}`
                }`}
                onClick={(e) => onClickHandler(e.currentTarget.textContent)}
              >
                {size}
              </li>
            </>
          ) : (
            ((startArrayNumber = 1),
            [...Array(size)].map((_, index) => (
              <li
                key={index}
                className={` ${styles.pageItem} ${
                  active === startArrayNumber && `${styles.active}`
                }`}
                onClick={(e) => onClickHandler(e.currentTarget.textContent)}
              >
                {startArrayNumber++}
              </li>
            )))
          )}
          {active < size ? (
            <li
              className={`${styles.pageItem} ${styles.next} ${styles.arrowIcon}`}
              onClick={() => onClickHandler(active + 1)}
            >
              &#8250;
            </li>
          ) : (
            <li
              className={`${styles.pageItem} ${styles.next} ${styles.arrowIcon} ${styles.disabled}`}
            >
              &#8250;
            </li>
          )}
        </ul>
      )}
    </>
  );
};

export default Pagination;
